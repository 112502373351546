<template>
  <div class="my-5 container">
    <div class="text-main">
      <h2 class="font-weight-bolder">MAP</h2>
    </div>
    <div class="mt-5 map-container">
      <div ref="map" id="map" style="width: 100%; height: 600px"></div>
      <div class="search-field-container">
        <div class="position-relative">
          <input
            id="search-field"
            type="text"
            placeholder="Search location"
            class="form-control search-field"
          />
          <b-spinner
            v-if="fetching"
            label="Spinning"
            class="search-field-spinner"
          ></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { initPlaces, removeInstance } from "../helpers/map";
import { mapState, mapActions } from "vuex";
export default {
  name: "ReservationSuccess",
  data() {
    return {
      map: null,
      markers: [],
    };
  },
  mounted() {
    this.initMap();
    initPlaces("search-field", ({ latitude, longitude }) => {
      this.fetchProperties(latitude, longitude);
    });
  },
  beforeDestroy() {
    removeInstance();
  },
  computed: {
    ...mapState("property", ["properties", "fetching"]),
  },
  methods: {
    ...mapActions("property", ["searchProperties"]),
    initMap() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLat = position.coords.latitude;
            const userLng = position.coords.longitude;

            if (typeof google !== "undefined") {
              // eslint-disable-next-line no-undef
              this.map = new google.maps.Map(this.$refs.map, {
                center: { lat: userLat, lng: userLng },
                zoom: 10,
                mapId: "c75a5c4a3741e065",
              });
            } else {
              console.error("Google Maps API is not loaded.");
            }
          },
          () => {
            console.error("Geolocation service failed.");
            this.initFallbackMap();
          }
        );
      } else {
        console.error("Your browser doesn't support geolocation.");
        this.initFallbackMap();
      }
    },
    initFallbackMap() {
      if (typeof google !== "undefined") {
        // eslint-disable-next-line no-undef
        this.map = new google.maps.Map(this.$refs.map, {
          center: { lat: -34.397, lng: 150.644 },
          zoom: 8,
          mapId: "c75a5c4a3741e065",
        });
      } else {
        console.error("Google Maps API is not loaded.");
      }
    },
    async fetchProperties(latitude, longitude) {
      if (latitude && longitude) {
        await this.searchProperties({
          latitude,
          longitude,
          radius: 100,
          page: 0,
          perPage: 1000,
        });

        this.clearMarkers();
        // eslint-disable-next-line no-undef
        this.map.setCenter(new google.maps.LatLng(latitude, longitude));
        this.map.setZoom(15);

        const geoCodeData = this.properties.content.map(
          ({ content: property }) => {
            if (property.media) {
              property.media
                .map((m) => {
                  m.order = m.sortOrderMain || Number.MAX_VALUE;
                  return m;
                })
                .sort((a, b) => a.order - b.order)
                .slice(0, 4);
            }
            return {
              id: property.id,
              name: property.name,
              ...property.geoCode,
              address: property.addresses.length ? property.addresses[0] : null,
              image: property.media.length
                ? property.media[0].url
                : "/images/property-default-image.jpg",
            };
          }
        );
        this.displayMarkers(geoCodeData);
      }
    },
    displayMarkers(geoCodeData) {
      geoCodeData.forEach((item) => {
        // eslint-disable-next-line no-undef
        const pin = new google.maps.marker.PinElement({
          background: "#ff762b",
          borderColor: "#ff762b",
          glyphColor: "white",
          scale: 1.2,
        });

        // eslint-disable-next-line no-undef
        const marker = new google.maps.marker.AdvancedMarkerElement({
          position: { lat: item.latitude, lng: item.longitude },
          map: this.map,
          title: item.name,
          content: pin.element,
        });

        // eslint-disable-next-line no-undef
        const infoWindow = new google.maps.InfoWindow({
          content: `
            <a href="/listing/${item.id}"><img src="${item.image}" style="width: 200px; margin-bottom: 5px;" /></a>
            <h5><a href="/listing/${item.id}">${item.name}</a></h5>
            <p>${item.address}</p>
          `,
        });

        marker.addListener("click", () => {
          infoWindow.open(this.map, marker);
        });

        this.markers.push(marker);
      });
    },
    clearMarkers() {
      this.markers.forEach((marker) => marker.setMap(null));
      this.markers = [];
    },
  },
};
</script>

<style scoped>
.map-container {
  position: relative;
}
.search-field-container {
  position: absolute;
  top: 9px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}
.search-field {
  width: 300px;
}
.search-field-spinner {
  position: absolute;
  right: 5px;
  top: 8px;
  width: 25px;
  height: 25px;
  color: #ff762b;
}
</style>
